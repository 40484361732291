import React, { useState } from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.scss";
import ProfilePic from "../../images/profile.svg";
import AtSymbole from "../../images/atSymbole.svg";

import axios from "axios";

const client = axios.create({
    baseURL: "/api/mailer"
});

const ContactForm = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [honeyPot, setHoneyPot] = useState("");

	const handleNameChange = (e) => {
		setName(e.target.value);
	};

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handleMessageChange = (e) => {
		setMessage(e.target.value);
	};

	const handleHoneyPot = (e) => {
		setHoneyPot(e.target.value);
	};

	const checkHoneyPot = (e) => {
		if (honeyPot === "") {
			if (name === "" || email === "" || message === "") {
				alert("Veuillez remplir tous les champs.");
				return;
			} else {
				const data = {
					from_name: name,
					from_email: email,
					from_message: message,
				};

				sendEmail(data);
				e.target.reset();
			}
		} else {
			return false;
		}
	};

	const sendEmail = async (data) => {
	    let form = new FormData();
	    form.append('name', data.from_name);
	    form.append('email', data.from_email);
	    form.append('message', data.from_message);
	    client.post('', form)
	    .then((response) => {
		console.log(response.data);
	    });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		checkHoneyPot(e);
		sendEmail(e);
	};

	return (
		<div id="contact-form-container">
			<form id="contact-form" action="" onSubmit={handleSubmit}>
				<div className="form-group">
					<img className="input-icon" src={ProfilePic} alt="profile icon" height="30px" />
					<input
						type="text"
						placeholder="Nom"
						className="form-input"
						onChange={handleNameChange}
					/>
				</div>
				<div className="form-group">
					<img src={AtSymbole} alt="@ icon" height="30px" />
					<input
						type="email"
						placeholder="Email"
						className="form-input"
						onChange={handleEmailChange}
					/>
				</div>
				<div className="form-group" style={{ display: "none" }}>
					<input
						style={{ display: "none" }}
						type="email-confirm"
						placeholder="Email-confirm"
						className="form-input"
						onChange={handleHoneyPot}
					/>
				</div>
				<div className="form-group">
					<textarea
						placeholder="Message"
						className="form-textarea"
						onChange={handleMessageChange}
					/>
				</div>
				<button type="submit" className="btn">
					Envoyer
				</button>
			</form>
		</div>
	);
};

export default ContactForm;
